const continents = [
	'Africa', 'Asia', 'Caribbean', 'East Asia', 'Europe', 'Latin America', 'Middle East',
	'North America', 'Oceiana', 'South Asia', 'United Kingdom'
]

const countries = {
	'Africa': [
		'SOUTH AFRICA',
		'NIGERIA',
		'CANADA',
		'UGANDA',
		'TANZANIA',
		'ISRAEL',
		'SPANISH',
		'Camarillo, CA 93012',
		'ETHIOPIA',
		'KENYA',
		'GHANA',
		'SWITZERLAND',
		'ZAMBIA',
		'FRANCE',
		'DENMARK',
		'Duzi-uMngeni Conservation Trust',
		'AMERICA',
		'EGYPT',
		'LEBANON',
		'ENGHLISH',
		'ESWATNI',
		'UNITED KINGDOM',
		'FRENCH',
		'BOTSWANA',
		'JORDAN',
		'TURKEY'
	],
	'Asia': [
		'INDIA', 'HONG KONG',
		'TAIWAN', 'CHINA',
		'KOREA, REPUBLIC OF', 'AMERICA',
		'MALAYSIA', 'THAILAND',
		'NEPAL', 'JAPAN',
		'FRANCE', 'PAKISTAN',
		'BELGIUM', 'SINGAPORE',
		'ISRAEL'
	],
	'Caribbean': [
		'PANAMA',
		'BAHAMAS',
		'COSTA RICA',
		'GUATEMALA',
		'CANADA',
		'MEXICO',
		'DOMINICAN REPUBLIC',
		'HONDURAS',
		'NICARAGUA',
		'SINE ESUTA AND SABA'
	], 'East Asia': [
		'INDIA', 'SINGAPORE',
		'THAILAND', 'HONG KONG',
		'JAPAN', 'BANGLADESH',
		'none', 'TAIWAN',
		'CHINA', 'AMERICA',
		'KONG', 'AUSTRALIA',
		'PHILIPPINES', 'HONGKONG',
		'VIETNAM', 'PAKISTAN',
		'MALAYSIA', 'CAMBODIA',
		'KINGDOM', 'INDONESIA',
		'KOREA,REPUBLIC OF', 'OF',
		'SRI LANKA', 'NEPAL',
		'MYANMAR', 'CANADA'
	],
	'Europe': [
		'CANADA', 'UNITED KINGDOM', 'SPAIN',
		'FRANCE', 'NETHERLANDS', 'IRELAND',
		'BELGIUM', 'ITALY', 'BULGARIA',
		'GERMANY', 'POLAND', 'ROMANIA',
		'PORTUGAL', 'SWITZERLAND', 'FINLAND',
		'AUSTRIA', 'NORWAY', 'BELARUS',
		'CZECHIA', 'TURKEY', 'DENMARK',
		'UKRAINE', 'LUXEMBOURG', 'REPUBLIC)',
		'HUNGARY', 'GREECE', 'ESTONIA',
		'SWEDEN', 'ZEALAND', 'MALTA',
		'SERBIA', 'NY 10017', 'SLOVENIA',
		'HRVATSKA)'
	],
	'Latin America': [
		'PANAMA',
		'COSTA RICA',
		'GUATEMALA',
		'CANADA',
		'MEXICO',
		'DOMINICAN REPUBLIC',
		'BAHAMAS',
		'SINT EUSTATIOUS AND SABA'
	], 'Middle East': [
		'RUSSIA',
		'PAKISTAN',
		'ENGLISH',
		'UNITED KINGDOM',
		'RUSSIAN',
		'UKARINE',
		'USA',
		'KAZAKHSTAN',
		'KYGYZTAN'
	],
	'North America': [
		'USA',
		'CANADA',
		'MEXICO',
		'ENGLISH',
		'AUSTRALIA',
		'FRENCH',
		'COSTA RICA'
	],
	'Oceiana': ['AUSTRALIA', 'NEW ZEALAND', 'FIJI', 'N/A'],
	'South Asia': [
		'INDIA', 'SINGAPORE',
		'THAILAND', 'HONG KONG',
		'JAPAN', 'BANGLADESH',
		'TAIWAN',
		'CHINA', 'AMERICA',
		'KONG', 'AUSTRALIA',
		'PHILIPPINES', 'HONGKONG',
		'VIETNAM', 'PAKISTAN',
		'MALAYSIA', 'CAMBODIA',
		'KINGDOM', 'INDONESIA',
		'KOREA,REPUBLIC OF', 'OF',
		'SRI LANKA', 'NEPAL',
		'MYANMAR', 'CANADA'
	], 'United Kingdom': ['United Kingdom']
}
const filteredCountries = [
	'SOUTH AFRICA',
	'NIGERIA',
	'CANADA',
	'UGANDA',
	'TANZANIA',
	'ISRAEL',
	'SPANISH',
	'Camarillo, CA 93012',
	'ETHIOPIA',
	'KENYA',
	'GHANA',
	'SWITZERLAND',
	'ZAMBIA',
	'FRANCE',
	'DENMARK',
	'Duzi-uMngeni Conservation Trust',
	'AMERICA',
	'EGYPT',
	'LEBANON',
	'ENGHLISH',
	'ESWATNI',
	'UNITED KINGDOM',
	'FRENCH',
	'BOTSWANA',
	'JORDAN',
	'TURKEY',
	'INDIA', 'HONG KONG',
	'TAIWAN', 'CHINA',
	'KOREA, REPUBLIC OF', 'AMERICA',
	'MALAYSIA', 'THAILAND',
	'NEPAL', 'JAPAN',
	'FRANCE', 'PAKISTAN',
	'BELGIUM', 'SINGAPORE',
	'ISRAEL',
	'PANAMA',
	'BAHAMAS',
	'COSTA RICA',
	'GUATEMALA',
	'CANADA',
	'MEXICO',
	'DOMINICAN REPUBLIC',
	'HONDURAS',
	'NICARAGUA',
	'SINE ESUTA AND SABA',
	'INDIA', 'SINGAPORE',
	'THAILAND', 'HONG KONG',
	'JAPAN', 'BANGLADESH',
	'none', 'TAIWAN',
	'CHINA', 'AMERICA',
	'KONG', 'AUSTRALIA',
	'PHILIPPINES', 'HONGKONG',
	'VIETNAM', 'PAKISTAN',
	'MALAYSIA', 'CAMBODIA',
	'KINGDOM', 'INDONESIA',
	'KOREA,REPUBLIC OF', 'OF',
	'SRI LANKA', 'NEPAL',
	'MYANMAR', 'CANADA',
	'CANADA', 'UNITED KINGDOM', 'SPAIN',
	'FRANCE', 'NETHERLANDS', 'IRELAND',
	'BELGIUM', 'ITALY', 'BULGARIA',
	'GERMANY', 'POLAND', 'ROMANIA',
	'PORTUGAL', 'SWITZERLAND', 'FINLAND',
	'AUSTRIA', 'NORWAY', 'BELARUS',
	'CZECHIA', 'TURKEY', 'DENMARK',
	'UKRAINE', 'LUXEMBOURG', 'REPUBLIC)',
	'HUNGARY', 'GREECE', 'ESTONIA',
	'SWEDEN', 'ZEALAND', 'MALTA',
	'SERBIA', 'NY 10017', 'SLOVENIA',
	'HRVATSKA',
	'PANAMA',
	'COSTA RICA',
	'GUATEMALA',
	'CANADA',
	'MEXICO',
	'DOMINICAN REPUBLIC',
	'BAHAMAS',
	'SINT EUSTATIOUS AND SABA',
	'RUSSIA',
	'PAKISTAN',
	'ENGLISH',
	'UNITED KINGDOM',
	'RUSSIAN',
	'UKARINE',
	'USA',
	'KAZAKHSTAN',
	'KYGYZTAN',
	'USA',
	'CANADA',
	'MEXICO',
	'ENGLISH',
	'AUSTRALIA',
	'FRENCH',
	'COSTA RICA', 'AUSTRALIA', 'NEW ZEALAND', 'FIJI',
	'INDIA', 'SINGAPORE',
	'THAILAND', 'HONG KONG',
	'JAPAN', 'BANGLADESH',
	'TAIWAN',
	'CHINA', 'AMERICA',
	'KONG', 'AUSTRALIA',
	'PHILIPPINES', 'HONGKONG',
	'VIETNAM', 'PAKISTAN',
	'MALAYSIA', 'CAMBODIA',
	'KINGDOM', 'INDONESIA',
	'KOREA,REPUBLIC OF', 'OF',
	'SRI LANKA', 'NEPAL',
	'MYANMAR', 'CANADA'
	, 'United Kingdom']
const all_countries = [
	"Afghanistan",
	"Albania",
	"Algeria",
	"American Samoa",
	"Andorra",
	"Angola",
	"Anguilla",
	"Antarctica",
	"Antigua and Barbuda",
	"Argentina",
	"Armenia",
	"Aruba",
	"Australia",
	"Austria",
	"Azerbaijan",
	"Bahamas (the)",
	"Bahrain",
	"Bangladesh",
	"Barbados",
	"Belarus",
	"Belgium",
	"Belize",
	"Benin",
	"Bermuda",
	"Bhutan",
	"Bolivia (Plurinational State of)",
	"Bonaire, Sint Eustatius and Saba",
	"Bosnia and Herzegovina",
	"Botswana",
	"Bouvet Island",
	"Brazil",
	"British Indian Ocean Territory (the)",
	"Brunei Darussalam",
	"Bulgaria",
	"Burkina Faso",
	"Burundi",
	"Cabo Verde",
	"Cambodia",
	"Cameroon",
	"Canada",
	"Cayman Islands (the)",
	"Central African Republic (the)",
	"Chad",
	"Chile",
	"China",
	"Christmas Island",
	"Cocos (Keeling) Islands (the)",
	"Colombia",
	"Comoros (the)",
	"Congo (the Democratic Republic of the)",
	"Congo (the)",
	"Cook Islands (the)",
	"Costa Rica",
	"Croatia",
	"Cuba",
	"Curaçao",
	"Cyprus",
	"Czechia",
	"Côte d'Ivoire",
	"Denmark",
	"Djibouti",
	"Dominica",
	"Dominican Republic (the)",
	"Ecuador",
	"Egypt",
	"El Salvador",
	"Equatorial Guinea",
	"Eritrea",
	"Estonia",
	"Eswatini",
	"Ethiopia",
	"Falkland Islands (the) [Malvinas]",
	"Faroe Islands (the)",
	"Fiji",
	"Finland",
	"France",
	"French Guiana",
	"French Polynesia",
	"French Southern Territories (the)",
	"Gabon",
	"Gambia (the)",
	"Georgia",
	"Germany",
	"Ghana",
	"Gibraltar",
	"Greece",
	"Greenland",
	"Grenada",
	"Guadeloupe",
	"Guam",
	"Guatemala",
	"Guernsey",
	"Guinea",
	"Guinea-Bissau",
	"Guyana",
	"Haiti",
	"Heard Island and McDonald Islands",
	"Holy See (the)",
	"Honduras",
	"Hong Kong",
	"Hungary",
	"Iceland",
	"India",
	"Indonesia",
	"Iran (Islamic Republic of)",
	"Iraq",
	"Ireland",
	"Isle of Man",
	"Israel",
	"Italy",
	"Jamaica",
	"Japan",
	"Jersey",
	"Jordan",
	"Kazakhstan",
	"Kenya",
	"Kiribati",
	"Korea (the Democratic People's Republic of)",
	"Korea (the Republic of)",
	"Kuwait",
	"Kyrgyzstan",
	"Lao People's Democratic Republic (the)",
	"Latvia",
	"Lebanon",
	"Lesotho",
	"Liberia",
	"Libya",
	"Liechtenstein",
	"Lithuania",
	"Luxembourg",
	"Macao",
	"Madagascar",
	"Malawi",
	"Malaysia",
	"Maldives",
	"Mali",
	"Malta",
	"Marshall Islands (the)",
	"Martinique",
	"Mauritania",
	"Mauritius",
	"Mayotte",
	"Mexico",
	"Micronesia (Federated States of)",
	"Moldova (the Republic of)",
	"Monaco",
	"Mongolia",
	"Montenegro",
	"Montserrat",
	"Morocco",
	"Mozambique",
	"Myanmar",
	"Namibia",
	"Nauru",
	"Nepal",
	"Netherlands (the)",
	"New Caledonia",
	"New Zealand",
	"Nicaragua",
	"Niger (the)",
	"Nigeria",
	"Niue",
	"Norfolk Island",
	"Northern Mariana Islands (the)",
	"Norway",
	"Oman",
	"Pakistan",
	"Palau",
	"Palestine, State of",
	"Panama",
	"Papua New Guinea",
	"Paraguay",
	"Peru",
	"Philippines (the)",
	"Pitcairn",
	"Poland",
	"Portugal",
	"Puerto Rico",
	"Qatar",
	"Republic of North Macedonia",
	"Romania",
	"Russian Federation (the)",
	"Rwanda",
	"Réunion",
	"Saint Barthélemy",
	"Saint Helena, Ascension and Tristan da Cunha",
	"Saint Kitts and Nevis",
	"Saint Lucia",
	"Saint Martin (French part)",
	"Saint Pierre and Miquelon",
	"Saint Vincent and the Grenadines",
	"Samoa",
	"San Marino",
	"Sao Tome and Principe",
	"Saudi Arabia",
	"Senegal",
	"Serbia",
	"Seychelles",
	"Sierra Leone",
	"Singapore",
	"Sint Maarten (Dutch part)",
	"Slovakia",
	"Slovenia",
	"Solomon Islands",
	"Somalia",
	"South Africa",
	"South Georgia and the South Sandwich Islands",
	"South Sudan",
	"Spain",
	"Sri Lanka",
	"Sudan (the)",
	"Suriname",
	"Svalbard and Jan Mayen",
	"Sweden",
	"Switzerland",
	"Syrian Arab Republic",
	"Taiwan",
	"Tajikistan",
	"Tanzania, United Republic of",
	"Thailand",
	"Timor-Leste",
	"Togo",
	"Tokelau",
	"Tonga",
	"Trinidad and Tobago",
	"Tunisia",
	"Turkey",
	"Turkmenistan",
	"Turks and Caicos Islands (the)",
	"Tuvalu",
	"Uganda",
	"Ukraine",
	"United Arab Emirates (the)",
	"United Kingdom of Great Britain and Northern Ireland (the)",
	"United States Minor Outlying Islands (the)",
	"United States of America (the)",
	"Uruguay",
	"Uzbekistan",
	"Vanuatu",
	"Venezuela (Bolivarian Republic of)",
	"Viet Nam",
	"Virgin Islands (British)",
	"Virgin Islands (U.S.)",
	"Wallis and Futuna",
	"Western Sahara",
	"Yemen",
	"Zambia",
	"Zimbabwe",
	"Åland Islands"
];
export { continents, countries, all_countries,filteredCountries }